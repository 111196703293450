import { Outlet, useNavigate } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';

import { AuthLayout } from './AuthLayout';

export const Auth0ProviderLayout = () => {
  const navigate = useNavigate();
  const onRedirectCallback = (appState: { returnTo: string }) => {
    navigate((appState && appState.returnTo) || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={import.meta.env.VITE_API_AUTH0_DOMAIN}
      clientId={import.meta.env.VITE_AUTH0_CLIENT_ID}
      onRedirectCallback={onRedirectCallback}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: import.meta.env.VITE_AUTH0_AUDIENCE,
        scope: 'openid offline_access read:current_user email',
      }}
      useRefreshTokens
      useRefreshTokensFallback
      cacheLocation="localstorage"
    >
      <AuthLayout>
        <Outlet />
      </AuthLayout>
    </Auth0Provider>
  );
};
