import { useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Outlet, redirect, useNavigate, useNavigation } from 'react-router-dom';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as amplitude from '@amplitude/analytics-browser';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';
import { useAuth0 } from '@auth0/auth0-react';

import Error from './Error';
import { useRouteMatchPath } from './';

import { ContainerLayout } from '@/components/layouts/ContainerLayout';
import Navbar from '@/components/Navbar';
import SpinningLoader from '@/components/SpinningLoader';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { getUser, useUser } from '@/features/users/api/getUser';
import { addAccessTokenInterceptor } from '@/lib/api';

// export const metadata = {
//   title: 'Joyful Health',
//   description: 'Joyful Health revenue intelligence app',
// };

export const Layout = () => {
  const navigate = useNavigate();

  const { state } = useNavigation();
  const {
    isAuthenticated,
    isLoading,
    error,
    loginWithRedirect,
    getAccessTokenSilently,
    user,
  } = useAuth0();
  const userQuery = useUser({
    userSub: user.sub,
    queryConfig: {
      enabled: !!user?.sub,
    },
  });

  const [isAccessTokenLoading, setIsAccessTokenLoading] = useState(true);
  const encounterRouteMatch = useRouteMatchPath('/:encounterId');

  useEffect(() => {
    if (error) {
      console.error(error);
      redirect('/error');
    }
    if (!isLoading && !isAuthenticated) loginWithRedirect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, isLoading, isAuthenticated]);

  useEffect(() => {
    if (!userQuery.isPending && userQuery.data) {
      if (!userQuery.data.tosAccepted) {
        navigate('/welcome');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userQuery]);

  useEffect(() => {
    if (isAuthenticated) {
      addAccessTokenInterceptor(getAccessTokenSilently);
      setIsAccessTokenLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isLoading]);

  if (
    state === 'loading' ||
    isAccessTokenLoading ||
    !isAuthenticated ||
    userQuery.isPending ||
    !userQuery.data.tosAccepted
  ) {
    return <SpinningLoader />;
  }

  if (encounterRouteMatch) {
    return (
      <>
        <Navbar />
        <div className="pb-10">
          <main>
            <ErrorBoundary FallbackComponent={Error}>
              <Outlet />
            </ErrorBoundary>
          </main>
        </div>
      </>
    );
  }

  return (
    <>
      <Navbar />
      <div className="py-10">
        <main>
          <ContainerLayout>
            <Outlet />
          </ContainerLayout>
        </main>
      </div>
    </>
  );
};
