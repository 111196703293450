import {
  createBrowserRouter,
  matchRoutes,
  type RouteObject,
  useLocation,
} from 'react-router-dom';

import { ProtectedRoute } from '../lib/auth';
import { Auth0ProviderLayout } from './Auth0ProviderLayout';
import { Layout } from './Layout';
import { Welcome } from './Welcome';

const routes: RouteObject[] = [
  { path: '/' },
  { path: '/admin' },
  { path: '/upload' },
  { path: '/encounters' },
  { path: '/:encounterId' },
  { path: '/welcome' },
  { path: '/dashboard' },
];

const mainRouter: RouteObject[] = [
  {
    path: '/',
    element: <ProtectedRoute component={Layout} />,
    children: [
      {
        index: true,
        lazy: async () => {
          const { Dashboard } = await import('./Dashboard');
          return { Component: Dashboard };
        },
      },
      {
        path: 'admin',
        lazy: async () => {
          const { Admin } = await import('./Admin');
          return { Component: Admin };
        },
      },
      {
        path: 'upload',
        lazy: async () => {
          const { Upload } = await import('./Upload');
          return { Component: Upload };
        },
      },
      {
        path: ':encounterId',
        lazy: async () => {
          const { EncounterRoute } = await import('./Encounter');
          return { Component: EncounterRoute };
        },
      },
      {
        path: 'test',
        lazy: async () => {
          const { Test } = await import('./Test');
          return { Component: Test };
        },
      },
      {
        path: 'encounters',
        lazy: async () => {
          const { Encounters } = await import('./Encounters');
          return { Component: Encounters };
        },
      },
    ],
  },
  {
    path: '/welcome',
    element: <ProtectedRoute component={Welcome} />,
  },
];

export const createRouter = () =>
  createBrowserRouter([
    {
      element: <Auth0ProviderLayout />,
      children: mainRouter,
    },
  ]);

export const useRouteMatchPath = (path) => {
  const location = useLocation();
  const matches = matchRoutes(routes, location);

  const getPath = (route: RouteObject) => {
    let path = route.path!;
    if (route.children?.length) {
      path += getPath(route.children[0]);
    }
    return path;
  };

  if (matches?.length) {
    return getPath(matches[0].route) === path;
  }

  return null;
};
