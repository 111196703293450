import { useEffect, useState } from 'react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Outlet, redirect, useNavigation } from 'react-router-dom';
import * as amplitude from '@amplitude/analytics-browser';
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';
import { useAuth0 } from '@auth0/auth0-react';

import SpinningLoader from '@/components/SpinningLoader';
import { addAccessTokenInterceptor } from '@/lib/api';

// export const metadata = {
//   title: 'Joyful Health',
//   description: 'Joyful Health revenue intelligence app',
// };

export const AuthLayout = ({ children }) => {
  amplitude.init('4ef98e9a7cc6c4dd0213ce7f76ecc036', { defaultTracking: true });
  const sessionReplayTracking = sessionReplayPlugin();
  amplitude.add(sessionReplayTracking);

  const { state } = useNavigation();
  const {
    isAuthenticated,
    isLoading,
    error,
    loginWithRedirect,
    getAccessTokenSilently,
    user,
  } = useAuth0();
  const [isAccessTokenLoading, setIsAccessTokenLoading] = useState(true);

  useEffect(() => {
    if (error) {
      console.error(error);
      redirect('/error');
    }
    if (!isLoading && !isAuthenticated)
      loginWithRedirect({ appState: { returnTo: window.location.pathname } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, isLoading, isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated) {
      if (user && user.email) {
        amplitude.setUserId(user.email);
        const identify = new amplitude.Identify()
          .set('email', user.email)
          .set('name', user.name);
        amplitude.identify(identify);
      }
      addAccessTokenInterceptor(getAccessTokenSilently);
      setIsAccessTokenLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isLoading]);

  if (state === 'loading' || isAccessTokenLoading || !isAuthenticated) {
    return <SpinningLoader />;
  }

  return <>{children}</>;
};
