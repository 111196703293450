import { type Auth0ContextInterface } from '@auth0/auth0-react';
import axios from 'axios';

export class FetchError extends Error {
  code: number;
  info: JSON;

  constructor(message: string, code: number, info: JSON) {
    super(message);
    this.code = code;
    this.info = info;
  }
}

export const api = axios.create({
  baseURL: import.meta.env.VITE_API_SERVER_URL,
});

api.interceptors.request.use((config) => {
  if (config.headers && !config.headers['Content-Type']) {
    config.headers['Content-Type'] = 'application/json';
  }
  return config;
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 404) {
      throw 404;
    }
    throw error;
  },
);

export const addAccessTokenInterceptor = (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
) => {
  api.interceptors.request.use(async (config) => {
    try {
      const accessToken = await getAccessTokenSilently({
        authorizationParams: {
          audience: import.meta.env.VITE_AUTH0_AUDIENCE,
          scope: 'read:current_user',
        },
      });
      config.headers.Authorization = `Bearer ${accessToken}`;

      return config;
    } catch (err) {
      console.log(err.error);
      console.log(err);

      throw new Error(err);
    }
  });
};

export const subscribeToSSE = (
  url: string,
  onMessage: (data: unknown) => void,
) => {
  const eventSource = new EventSource(url);

  eventSource.onmessage = (event) => {
    onMessage(event.data);
  };

  eventSource.onerror = (error) => {
    console.error('SSE Error:', error);
    eventSource.close();
  };

  return () => {
    eventSource.close();
  };
};
