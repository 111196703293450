import { queryOptions, useQuery } from '@tanstack/react-query';

import { api, FetchError } from '@/lib/api';
import { type QueryConfig } from '@/lib/reactQuery';
import { type GetUserResponse } from '@/types/api';

export async function getUser({ userSub }: { userSub: string }) {
  try {
    const { data } = await api.get<GetUserResponse>(`/api/users/${userSub}`);

    return {
      isAdmin: data.is_admin,
      accountId: data.account_id,
      tosAccepted: data.tos_accepted,
    };
  } catch (error) {
    throw new FetchError(
      'An error occurred while fetching the user',
      error.response.status,
      error.response.data,
    );
  }
}

const getUserQueryOptions = (userSub: string, adminAccountId?: string) => {
  return queryOptions({
    queryKey: ['users', userSub, adminAccountId],
    queryFn: () => getUser({ userSub }),
  });
};

export type UseUserOptions = {
  userSub: string;
  adminAccountId?: string;
  queryConfig?: QueryConfig<typeof getUserQueryOptions>;
};

export const useUser = ({
  userSub,
  adminAccountId,
  queryConfig,
}: UseUserOptions) => {
  return useQuery({
    ...getUserQueryOptions(userSub, adminAccountId),
    ...queryConfig,
  });
};
